import React from 'react';
import * as _ from 'lodash';
import CreateBanner from './CreateBanner';
import {
  ErrorDisplay,
  Loader,
  Typography,
  Href,
  Card,
} from '../../../components/index';
import { Col, Row } from 'react-styled-flexboxgrid';
import Styled from './Marketplace.style';
import { getMarketplaceFeature } from '../../../helpers';
import ListBanners from './ListBanners';

interface MarketplaceProps {
  createMarketplaceBanner: Function;
  updateMarketplaceBanner: Function;
  deleteMarketplaceBanner: Function;
  listMarketplaceBanner: Function;
  marketplace: any;
  getDownloadCsvData: Function;
  getBannerTag: Function;
  user: any;
  banner: any;
  getCategories: Function;
  categories: any;
}

export const Marketplace: React.FC<MarketplaceProps> = (props) => {
  const {
    createMarketplaceBanner,
    getBannerTag,
    listMarketplaceBanner,
    marketplace,
    user,
    banner,
    getCategories,
    categories,
  } = props;

  const listBanners = _.get(marketplace, 'listMarketplaceBanner.data');
  const loadingCreate = _.get(
    marketplace,
    'createMarketplaceBanner.status.loading'
  );
  const userData = _.get(user, 'data');

  const bannerTagData = _.get(banner, 'getBannerTag.data');

  const { error, loading, done } = _.get(
    marketplace,
    'listMarketplaceBanner.status'
  );
  const licence = getMarketplaceFeature(userData, 'MARKETPLACE_BANNERS');
  const categoriesData = _.get(categories, 'data');

  React.useEffect(() => {
    listMarketplaceBanner();
    getBannerTag();
    getCategories();
  }, []);
  if (error) {
    return <ErrorDisplay />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!licence) {
    return (
      <Row>
        <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
          <Styled.Banner>
            <Styled.ImageWrap>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/marketing.svg"
                alt="Marketing"
              />
            </Styled.ImageWrap>
            <Styled.TextWrap>
              <Typography
                size="1"
                text="Feature not available"
                style={{ marginTop: '0px' }}
              />
              <Typography
                size="3"
                text="Contact our sales team to enable this feature."
                style={{ marginTop: '0px' }}
              />
              <Href
                icon="send"
                text="Contact us"
                style={{ marginTop: '40px' }}
                href="mailto:claudiu.soare@savewatersavemoney.com"
              />
            </Styled.TextWrap>
          </Styled.Banner>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Card>
          <CreateBanner
            list={listMarketplaceBanner}
            onSubmit={createMarketplaceBanner}
            loading={loadingCreate}
            bannerTags={bannerTagData}
            user={userData}
            categories={categoriesData}
          />
        </Card>
      </Col>
      <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Card>
          <ListBanners data={listBanners} />
        </Card>
      </Col>
    </Row>
  );
};

export default Marketplace;
