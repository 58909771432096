import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getCategories = () => {
  return {
    type: 'GET_CATEGORIES',
    payload: axios.get(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}marketplace/banner/availableCategories`
    ),
  };
};
