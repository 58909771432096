import styled from 'styled-components';

const AddLogo = styled.div`
  min-height: 46px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-left: 0px;
  border-right: 0px;
  font-size: 13.3px;
  margin: 0px;
  width: 100%;
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; /* give the beautiful '...' effect */
  cursor: pointer;
  transition: all 100ms ease-in-out;
`;
const IconWrap = styled.div`
  z-index: 9999;
  border-left: 1px solid black;
  border: 1px solid #e2e8f0;
  min-height: 46px;
  padding: 0 16px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
`;
const Wrapper = styled.div`
  height: auto;
  position: relative;
  label {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 6px;
  }
  &:hover {
  }
  ${AddLogo} {
    &:hover {
      background: #e2e8f0;
      transition: all 100ms ease-in-out;
      & ~ ${IconWrap} {
        background: #e2e8f0;
      }
    }
  }
  ${IconWrap} {
    &:hover {
      background: #e2e8f0;
      transition: all 100ms ease-in-out;
      border-right: 1px solid #fff;
      & ~ ${AddLogo} {
        background: #e2e8f0;
      }
    }
  }
`;
const Error = styled.div`
  color: red;
  font-size: 14px;
  padding: 4px 0px;
  position: absolute;
  margin-top: -22px;
`;

const ButtonWrap = styled.button`
  z-index: 9999;
  border-left: 1px solid black;
  border: 1px solid #e2e8f0;
  min-height: 46px;
  padding: 0 16px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  &:hover {
    background: rgb(255, 216, 216);
    transition: all 100ms ease-in-out;
  }
`;
const Disclaimer = styled.p`
  font-size: 13px;
  font-style: italic;
  margin-bottom: 16px;
  margin-top: 2px;
`;
export default { Wrapper, AddLogo, Error, IconWrap, ButtonWrap, Disclaimer };
