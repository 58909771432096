import React from 'react';
import Styled from './DefaultLayout.style';
import SideNav from '../SideNav';
import { Outlet } from 'react-router-dom';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { LocalStorage } from 'ttl-localstorage';
import { useResponsivity, capitalizeFirstLetter } from '../../helpers';
import { Wrap, Footer, TopNav } from '../index';
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import store from 'store2';
interface DefaultLayoutProps {
  metadata: any;
  user: any;
  getUser: Function;
  exitUser: Function;
  exitFullscreen?: any;
  mozCancelFullScreen?: any;
  webkitExitFullscreen?: any;
  fullscreenElement?: any;
  mozFullScreenElement?: any;
  webkitFullscreenElement?: any;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const { user, getUser, exitUser } = props;
  const userData = _.get(user, 'data');
  React.useEffect(() => {
    LocalStorage.get('swsmUser');
    getUser();

    if (userData) {
      store('user', userData);
    }
  }, []);

  const location = useLocation();
  const [isMaximise, setMaximise] = React.useState(true);
  const isMobile = useResponsivity();
  const pathname = location.pathname.substring(1);
  const email = _.get(user, 'data.email');
  const roles = _.get(user, 'data.roles');
  const adminRole = roles && roles.find((o) => o === 'ROLE_SUPER_ADMIN');
  const impersonating = _.get(user, 'data.impersonating');

  return (
    <Styled.Wrap isMobile={isMobile}>
      <SideNav maximise={isMaximise} />
      <TopNav
        maximise={isMaximise}
        impersonating={impersonating}
        email={email}
        adminRole={adminRole}
        exitUser={exitUser}
        onClickMaximise={() => setMaximise(!isMaximise)}
      />
      <Styled.Main
        style={isMaximise ? { marginLeft: '300px' } : { marginLeft: '70px' }}
      >
        <Styled.Content>
          <Grid fluid>
            <Row>
              <Col md={12} xs={12} sm={12}>
                {pathname.includes('campaigns/') ||
                pathname.includes('myshop/') ||
                pathname.includes('success') ? null : (
                  <Wrap
                    title={
                      pathname === ''
                        ? 'Overview'
                        : pathname === 'myshop'
                        ? 'GetWaterSmart'
                        : pathname === 'utm-branding'
                        ? 'UTM Branding'
                        : capitalizeFirstLetter(pathname.replace(/-/g, ' '))
                    }
                  />
                )}
                <div
                  style={{
                    minHeight: '600px',
                    width: '100%',
                    marginBottom: '16px',
                  }}
                >
                  <Outlet />
                </div>
              </Col>
            </Row>
          </Grid>
        </Styled.Content>

        <Footer />
      </Styled.Main>
    </Styled.Wrap>
  );
};

export default DefaultLayout;
