import React from 'react';
import {
  Input,
  Button,
  Typography,
  AddImage,
  Notification,
  Select,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  useFormik,
  Field,
  FormikProvider,
  FieldArray,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Styled from './Marketplace.style';
import * as _ from 'lodash';

interface CreateBannerProps {
  onSubmit: Function;
  list: Function;
  loading?: boolean;
  error?: string;
  done?: boolean;
  user: any;
  bannerTags: any;
  categories: any;
}
type Certification = {
  type: string;
  level: undefined | number;
  flowRate: undefined | number;
  licenceNo: undefined | string;
};

type FormValues = {
  tagIds: string[];
  title: string;
  description: string;
  theme: string;
  buttonPath: string;
  buttonText: string;
  logo: string;
  productDescription: string;
  productUniqueness: string;
  categoryId: string;
  certifications: Certification[];
};

export const CreateBanner: React.FC<CreateBannerProps> = (props) => {
  const { onSubmit, list, loading, bannerTags, categories } = props;

  const certTagName = 'WELS';
  const findTagName =
    bannerTags && bannerTags.find((el) => el.title === certTagName);
  const CreateBannerSchema = Yup.object().shape({
    title: Yup.string()
      .required('Required')
      .max(64, 'Maximum allowed is 64 characters.'),
    description: Yup.string().required('Required'),
    buttonPath: Yup.string().required('Please enter website URL'),
    logo: Yup.mixed()
      .required('Image is required')
      .test(
        'fileSize',
        'Image is too big! Max image size is 2MB',
        (value) => value && value.size <= 2000000
      ),
    productDescription: Yup.string(),
    productUniqueness: Yup.string(),
    categoryId: Yup.number(),
    tagIds: Yup.array().of(Yup.string()),
    certifications: Yup.array().of(
      Yup.object().shape({
        level: Yup.number()
          .max(6, 'Maximum level allowed is 6')
          .min(0, 'Maximum level allowed is 6')
          .when('$tagIds', {
            is: (tags) =>
              tags?.includes(findTagName && findTagName.id.toString()),
            then: Yup.number().required('Required'),
            otherwise: Yup.number().notRequired(),
          }),
        flowRate: Yup.number()
          .max(99, 'Maximum flow rate allowed is 10')
          .min(0, 'Maximum level allowed is 6')
          .when('$tagIds', {
            is: (tags) =>
              tags?.includes(findTagName && findTagName.id.toString()),
            then: Yup.number().required('Required'),
            otherwise: Yup.number().notRequired(),
          }),
        licenceNo: Yup.string().when('$tagIds', {
          is: (tags) =>
            tags?.includes(findTagName && findTagName.id.toString()),
          then: Yup.string().required('Required'),
          otherwise: Yup.string().notRequired(),
        }),
      })
    ),
  });

  const renderOptionsCat = () => {
    return (
      categories &&
      categories.children.map((el, index) => {
        return (
          <React.Fragment key={`option-${el.parent_id}-${el.id}-${index}`}>
            <option value={parseInt(el.id)}>{el.title}</option>
            {el.children &&
              el.children.map((elem) => {
                return (
                  <React.Fragment
                    key={`suboption-${elem.parent_id}-${elem.id}`}
                  >
                    <option value={parseInt(elem.id)}>
                      &nbsp;&nbsp;{elem.title}
                    </option>
                    {elem.children &&
                      elem.children.map((element) => {
                        return (
                          <option
                            key={`suboeption-${element.parent_id}-${element.id}`}
                            value={parseInt(element.id)}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element.title}
                          </option>
                        );
                      })}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      title: '',
      description: '',
      theme: 'lightTheme',
      buttonPath: '',
      buttonText: 'Find out more',
      logo: '',
      productDescription: '',
      productUniqueness: '',
      categoryId: '',
      tagIds: [],
      certifications: [
        {
          type: 'wels',
          level: undefined,
          flowRate: undefined,
          licenceNo: undefined,
        },
      ],
    },
    validationSchema: CreateBannerSchema,
    onSubmit: (values, { setSubmitting }) => {
      const numberArray = formik.values.tagIds.toString();
      const certArray = JSON.stringify(formik.values.certifications);
      const amendedFields = {
        ...values,
        tagIds: numberArray,
        certifications: certArray,
      };
      onSubmit(
        amendedFields,
        () => {
          setSubmitting(false);
          setImage(null);
          setTimeout(() => {
            list();
          }, 500);
        },
        () => {
          setSubmitting(false);
          console.log('failed');
        }
      );
      setSubmitting(false);
    },
  });

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };
  const [bannerImage, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');

  const imgUrl = bannerImage
    ? `${URL.createObjectURL(bannerImage)}`
    : 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/free-products.jpg';

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} xs={12} sm={12}>
            <Typography text="Create advert" size="2" />
          </Col>
          <Col md={8} sm={12} xs={12}>
            <Row>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Title"
                  placeholder="Title"
                  style={{ marginBottom: '8px' }}
                  value={formik.values.title}
                  error={
                    formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : undefined
                  }
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Select
                  value={formik.values.categoryId}
                  onChange={formik.handleChange}
                  name="categoryId"
                  id="categoryId"
                  placeholder="Select category"
                  label="Category"
                >
                  {renderOptionsCat()}
                </Select>
              </Col>
              <Col md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                <div
                  style={{
                    padding: '16px',
                    border: '1px solid #e2e8f0',
                    borderRadius: '6px',
                    marginBottom: '16px',
                  }}
                >
                  <div
                    style={{
                      marginTop: '-26px',
                      background: 'white',
                      width: 'min-content',
                      padding: '0px 16px',
                      marginBottom: '8px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    Certifications
                  </div>
                  {bannerTags &&
                    bannerTags.map((el, index) => {
                      return (
                        <label key={index} style={{ marginRight: '16px' }}>
                          <Field
                            type="checkbox"
                            name="tagIds"
                            id="tagIds"
                            value={`${el.id}`}
                          />
                          {el.title}
                        </label>
                      );
                    })}
                </div>
              </Col>
              {formik.values.tagIds.includes(
                findTagName && findTagName.id.toString()
              ) && (
                <Col md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                  <div
                    style={{
                      padding: '16px',
                      border: '1px solid #e2e8f0',
                      borderRadius: '6px',
                      marginBottom: '16px',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '-26px',
                        background: 'white',
                        padding: '0px 16px',
                        marginBottom: '8px',
                        fontSize: '14px',
                        fontWeight: 600,
                        width: 'max-content',
                      }}
                    >
                      {findTagName && findTagName.title} Rating
                    </div>
                    <FieldArray name="certifications">
                      {() => (
                        <div>
                          {formik.values.certifications.map((cert, index) => (
                            <Row key={index}>
                              <Col md={4} xs={12} sm={12}>
                                <Styled.RatingField>
                                  <label
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 600,
                                      paddingBottom: '6px',
                                    }}
                                    htmlFor={`certifications.${index}.level`}
                                  >
                                    Level
                                  </label>
                                  <Field
                                    style={{
                                      borderRadius: '6px',
                                      border: '1px solid #e2e8f0',
                                      minHeight: '36px',
                                      padding: '0 16px',
                                    }}
                                    name={`certifications.${index}.level`}
                                    type="number"
                                    placeholder="Level"
                                  />
                                  <ErrorMessage
                                    name={`certifications.${index}.level`}
                                    component="div"
                                    className="field-error"
                                  />
                                </Styled.RatingField>
                              </Col>
                              <Col md={4} xs={12} sm={12}>
                                <Styled.RatingField>
                                  <label
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 600,
                                      paddingBottom: '6px',
                                    }}
                                    htmlFor={`certifications.${index}.flowRate`}
                                  >
                                    Flow rate
                                  </label>
                                  <Field
                                    style={{
                                      borderRadius: '6px',
                                      border: '1px solid #e2e8f0',
                                      minHeight: '36px',
                                      padding: '0 16px',
                                    }}
                                    name={`certifications.${index}.flowRate`}
                                    type="number"
                                    placeholder="Flow rate"
                                  />
                                  <ErrorMessage
                                    name={`certifications.${index}.flowRate`}
                                    component="div"
                                    className="field-error"
                                  />
                                </Styled.RatingField>
                              </Col>
                              <Col md={4} xs={12} sm={12}>
                                <Styled.RatingField>
                                  <label
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 600,
                                      paddingBottom: '6px',
                                    }}
                                    htmlFor={`certifications.${index}.licenceNo`}
                                  >
                                    Licence no
                                  </label>
                                  <Field
                                    style={{
                                      borderRadius: '6px',
                                      border: '1px solid #e2e8f0',
                                      minHeight: '36px',
                                      padding: '0 16px',
                                    }}
                                    name={`certifications.${index}.licenceNo`}
                                    type="text"
                                    placeholder="Licence no."
                                  />
                                  <ErrorMessage
                                    name={`certifications.${index}.licenceNo`}
                                    component="div"
                                    className="field-error"
                                  />
                                </Styled.RatingField>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </Col>
              )}

              <Col sm={12} xs={12} md={6}>
                <Input
                  id="buttonPath"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Button path"
                  placeholder="Button path"
                  style={{ marginBottom: '8px' }}
                  value={formik.values.buttonPath}
                  error={
                    formik.errors.buttonPath && formik.touched.buttonPath
                      ? formik.errors.buttonPath
                      : undefined
                  }
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <AddImage
                  onClearClick={() => {
                    setImage(null);
                    formik.setFieldValue('logo', '');
                  }}
                  onImageChange={() => onImageChange}
                  name="logo"
                  label="Advert image"
                  disclaimer="Maximun file size 2MB.Recommended aspect ratio is: 5:4. (A minimum resolution of 300x250 pixels)."
                  filename={filename ? filename : 'Please select a image'}
                  error={
                    formik.errors.logo && formik.touched.logo
                      ? formik.errors.logo
                      : undefined
                  }
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="description"
                  type="text"
                  label="Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Description"
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : undefined
                  }
                />
              </Col>

              <Col sm={12} xs={12} md={6}>
                <Input
                  id="productDescription"
                  type="text"
                  icon="pen"
                  label="Search term(s)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Search term(s)"
                  value={formik.values.productDescription}
                />
              </Col>
              {/* <Col sm={12} xs={12} md={6}>
                <Input
                  id="productUniqueness"
                  type="text"
                  label="Product Sustainability story"
                  icon="pen"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Product Sustainability story"
                  value={formik.values.productUniqueness}
                />
              </Col> */}

              <Col
                sm={12}
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginTop: '8px',
                }}
              >
                <Button disabled={formik.isSubmitting || loading} type="submit">
                  Create advert
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <Styled.PreviewBanner>
              <Styled.ImgWrap
                style={{
                  backgroundImage: `url(${imgUrl})`,
                }}
              />
              <Styled.CopyWrap>
                <Typography
                  size="p"
                  text={
                    formik.values.title ? formik.values.title : 'Sample title'
                  }
                  style={{
                    marginBottom: '8px',
                    fontSize: '16px',
                    minHeight: '52px',
                    color: 'rgb(44, 66, 108)',
                    fontWeight: 500,
                  }}
                />
              </Styled.CopyWrap>
            </Styled.PreviewBanner>
          </Col>
        </Row>
      </form>
    </FormikProvider>
  );
};

export default CreateBanner;
