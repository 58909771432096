import styled from 'styled-components';

const Wrapper = styled.div`
  height: auto;
  position: relative;
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  input {
    min-height: 46px;
    padding: 0 16px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #e2e8f0;
    border-left: 0px;
    width: 100%;
    &:focus-visible {
      background: #e2e8f0;
      outline: 0px;
    }
  }
  input[type='date'] {
    font-family: 'Open Sans', sans-serif;
  }
  label {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 6px;
  }
  .extraClass {
    max-width: 300px;
    text-align: center;
    background-color: #0784ab;
  }
`;
const Error = styled.div`
  color: red;
  font-size: 14px;
  padding: 6px 4px;
  position: absolute;
  margin-top: -16px;
`;
const IconWrap = styled.div`
  z-index: 9999;
  border-left: 1px solid black;
  border: 1px solid #e2e8f0;
  min-height: 46px;
  padding: 0 16px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
`;

export default { Wrapper, Error, IconWrap };
