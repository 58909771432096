import React from 'react';
import App from '../App';
import { ToastContainer } from 'react-toastify';

interface AppWrapProps {
  metadata: any;
}

const AppWrap: React.FC<AppWrapProps> = (props) => {
  const componentRef = React.createRef<HTMLDivElement>();
  return (
    <div ref={componentRef}>
      <App />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AppWrap;
